import {
  renderLink,
  renderProject,
  renderImage,
  renderSong,
} from "./components";
import Header from "./Header";

const image_list = [
  "https://images.puqing.work/1660712180602d2962645da10356c.jpg",
  "https://images.puqing.work/16607121911105674baec7b41a78d.jpg",
  "https://images.puqing.work/16607122079657fbc6de41ef3744b.jpg",
  "https://images.puqing.work/166071221913824e19305e07cf1d1.jpg",
];

const recently_listened = [
  {
    name: "春日部的告别",
    image:
      "http://p1.music.126.net/hi5HYFC3l6wngL1TqK6k-w==/109951166695222424.jpg",
    link: "https://music.163.com/#/song?id=1852326658",
  },
  {
    name: "小公主",
    image:
      "http://p2.music.126.net/P98sLvH6u_ZnM6E6T7q-uA==/18652115255139215.jpg",
    link: "https://music.163.com/#/song?id=445702011",
  },
  {
    name: "连同你群青般的温柔 一起沉溺",
    image:
      "https://p2.music.126.net/CfybImbjUSszdWOwDjDUJQ==/109951165271422032.jpg",
    link: "https://music.163.com/#/song?id=1474142439",
  },
];

const LINKS = [
  {
    title: "Blog",
    url: "https://blog.puqing.work",
    abs: "blog.puqing.work",
    logo: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className=" h-6 w-6"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001 6.971 2.789Zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z"
        />
      </svg>
    ),
  },
  {
    title: "Github",
    url: "https://github.com/AndPuQing",
    abs: "@AndPuQing",
    logo: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        className=" h-6 w-6"
        viewBox="0 0 16 16"
      >
        <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
      </svg>
    ),
  },
  {
    title: "Search",
    url: "https://search.puqing.work",
    abs: "search.puqing.work",
    logo: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z" />
      </svg>
    ),
  },
];

const PROJECTS = [
  {
    title: "Papilot",
    url: "https://github.com/AndPuQing/Papilot",
    abs: "an open-source GitHub Copilot server based PaddleNLP",
    img: "https://images.puqing.work/image9c90e9dd7752fb70.png",
  },
  {
    title: "PyGeomesh",
    url: "https://github.com/AndPuQing/PyGeomesh",
    abs: "PyGeomesh is a tool for generating discretized points from geometry.",
    img: "https://avatars.githubusercontent.com/u/107790692?s=400&u=593a7b74f7d2f21d2a057488e1c3e164b14ee3f6&v=4",
  },
];

function App() {
  return (
    <div className=" bg-fixed scroll-smooth min-h-100vh flex flex-col bg-gradient-to-br from-hex-1d5584 via-hex-dbdbce to-hex-f7d8a9">
      <Header />
      <div className="bg-hex-edefe3 rounded-t-50px flex-1 py-4 pb-12 lg:py-12 z-10">
        <div className="mx-auto max-w-1024px px-10px lg:px-20px gap-10 flex flex-col">
          <div className="flex flex-col bg-white px-8 py-10 lg:p-10 gap-5 rounded-50px">
            <div className="flex items-center justify-between">
              <span className="uppercase font-bold text-lg text-black">
                links
              </span>
            </div>
            <div className="grid gap-20px md:grid-cols-3">
              {LINKS.map((link) =>
                renderLink(link.title, link.url, link.abs, link.logo)
              )}
            </div>
          </div>
          <div className="flex flex-col bg-white px-8 py-10 lg:p-10 gap-5 rounded-50px">
            <div className="flex items-center justify-between">
              <span className="uppercase font-bold text-lg text-black">
                projects
              </span>
            </div>
            <div className="grid gap-20px md:grid-cols-3">
              {PROJECTS.map((project) =>
                renderProject(
                  project.title,
                  project.url,
                  project.abs,
                  project.img
                )
              )}
            </div>
          </div>
          <div className="flex flex-col bg-white px-8 py-10 lg:p-10 gap-5 rounded-50px">
            <div className="flex items-center justify-between">
              <span className="uppercase font-bold text-lg text-black">
                Gallery
              </span>
            </div>
            <div className="gap-20px grid md:grid-cols-4 md:auto-rows-min">
              {image_list.map((image, index) => renderImage(image, index))}
            </div>
          </div>
          <div className="flex flex-col bg-white px-8 py-10 lg:p-10 gap-5 rounded-50px">
            <div className="flex items-center justify-between">
              <span className="uppercase font-bold text-lg text-black">
                recently listened
              </span>
            </div>
            <div className="gap-20px grid grid-cols-2 md:grid-cols-4 auto-cols-min auto-rows-min">
              {recently_listened.map((song, index) => renderSong(song, index))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
