export function renderLink(title, url, abs, logo) {
  return (
    <a
      href={url}
      className="bg-blue-100 rounded-3xl lg:rounded-3xl py-20px px-30px flex gap-30px items-center"
      target="_blank"
      rel="noreferrer"
    >
      {logo}
      <div className="flex flex-col">
        <span className="text-lg font-bold">{title}</span>
        <span className="font-medium tracking-wider text-sm opacity-70">
          {abs}
        </span>
      </div>
    </a>
  );
}

export function renderProject(title, url, abs, img) {
  return (
    <a
      className="bg-orange-100 flex flex-col rounded-40px overflow-hidden"
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <img
        className="h-24 object-cover"
        src={img}
        alt="project"
        loading="lazy"
      />
      <div className="uppercase flex flex-col p-5 px-6 flex-1 text-black">
        <span className="font-bold">{title}</span>
        <span className="font-medium text-xs leading-5 opacity-70">{abs}</span>
      </div>
    </a>
  );
}

export function renderImage(image, index) {
  return index === 0 ? (
    <img
      key={index}
      className="object-cover rounded-40px col-span-2 row-span-2 h-50 md:h-80 aspect-square"
      src={image}
      loading="lazy"
      alt="none"
    />
  ) : (
    <img
      key={index}
      className="object-cover rounded-30px md:rounded-40px md:h-40 aspect-square"
      src={image}
      loading="lazy"
      alt="none"
    />
  );
}

export function renderSong(song, index) {
  return index === 0 ? (
    <a
      className="flex items-center col-span-2 row-span-2"
      key={index}
      href={song.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="aspect-square rounded-3xl lg:rounded-30px object-cover h-30 md:h-50"
        src={song.image}
        alt={song.name}
        loading="lazy"
      />
    </a>
  ) : (
    <a
      className="flex items-center"
      href={song.link}
      key={index}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="aspect-square rounded-3xl lg:rounded-30px object-cover h-30 md:h-40"
        src={song.image}
        alt={song.name}
        loading="lazy"
      />
    </a>
  );
}
